<template>
  <div class="wrapper">
    <div class="tip">
      <div class="tip-text">
        <div>欢迎认证个人信息</div>
        <div>认证后可以体验完整功能</div>
      </div>
      <div class="tip-img">
        <img :src="require('./icon_gongpai2@2x.png')" />
      </div>
    </div>
    <van-form @submit="onSubmit" :show-error-message="true" :show-error="false">
      <van-cell-group inset class="form">
        <div class="title">请输入实名认证信息</div>
        <van-field
          v-model="form.userName"
          label="姓名"
          placeholder="请输入用户名"
          :rules="rules.userName"
        />
        <van-field
          v-model="form.idcard"
          label="身份证号"
          placeholder="请输入身份证号"
          :rules="rules.idcard"
        />
        <van-field
          v-model="form.phone"
          label="手机号"
          placeholder="请输入手机号"
          :rules="rules.phone"
        />
        <van-field
          v-model="form.verCode"
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="rules.verCode"
        >
          <template #button>
            <CodeBtn :phone="form.phone" action="/userinfo/uia/senSmsCode" />
          </template>
        </van-field>
      </van-cell-group>
      <div class="btn-wrapper">
        <van-button type="primary" block round native-type="submit">
          完成
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import CodeBtn from "@/components/CodeBtn";
import { Toast } from "vant";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      userName: "",
      form: {
        userName: "",
        idcard: "",
        phone: "",
        verCode: "",
      },
      rules: {
        userName: [{ required: true, message: "请输入用户名" }],
        idcard: [
          { required: true, message: "请输入身份证号" },
          {
            pattern:
              /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            message: "身份证号格式不正确",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号" },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "手机号格式不正确",
          },
        ],
        verCode: [{ required: true, message: "请输入验证码" }],
      },
    };
  },
  components: { CodeBtn },
  computed: {
    openid() {
      return Cookies.get("openid");
    },
  },
  mounted() {},
  methods: {
    getCode() {},
    onSubmit() {
      const data = {
        ...this.form,
        openid: this.openid,
      };
      this.$store.dispatch("global/postRealNameAuthen", data).then((res) => {
        setTimeout(() => {
          this.$router.push("/");
        }, 500);
      });
    },
  },
};
</script>'
<style lang="scss" scoped>
.wrapper {
  height: 100%;
  background: #ddd;
  padding-top: 0.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #3c37d2 0%, #f0f0f0 50%);
  overflow: auto;
}
.title {
  padding: 0.4rem 0 0.2rem;
  text-align: center;
}
.form {
  /deep/ .van-cell {
    flex-direction: column;
  }
}
.btn-wrapper {
  margin: 2rem 0.2rem 0.2rem;
  // position: absolute;
  // bottom: 0.4rem;
  // left: 16px;
  // right: 16px;
}
.tip {
  margin: 0.2rem 16px 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tip-text {
    color: #ffffff;
    font-size: 0.32rem;
    color: #fff;
    text-shadow: 0px 0px 10px #3731df;
  }
  .tip-img {
    width: 0.8rem;
    height: 0.8rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>