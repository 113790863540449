<!--
 * @Date: 2021-11-03 15:58:11
 * @LastEditTime: 2021-11-24 15:10:42
 * @Description: 获取验证码组件
-->
<template>
  <van-button
    class="verify-code-button"
    type="primary"
    size="mini"
    round
    @click="handleClick"
    :disabled="disabled"
    :loading="loading"
    loading-text="获取中"
    native-type="button"
  >
    {{ disabled ? `${lefTime}s后获取` : text }}
  </van-button>
</template>
<script>
import { getVerifyCode } from "@/api/pclogin/uia";
import { Toast } from "vant";
const phoneReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
import service from "@/utils/request";
export default {
  data() {
    return {
      lefTime: 0, //剩余时间
      timer: null, //定时器
      loading: false,
      disabled: false
    };
  },
  props: {
    countDown: { type: Number, default: 60 }, //倒计时时间
    text: { type: String, default: "获取验证码" },
    phone: String,
    action: {
      type: String,
      default: "/userinfo/sms/sendSMSCodeFr"
    }
  },
  beforeDestroy() {
    this.init();
  },
  methods: {
    handleClick() {
      if (!phoneReg.test(this.phone)) {
        Toast("手机号不正确！");
        return;
      }
      this.loading = true;
      service({
        url: this.action,
        method: "get",
        params: {
          phone: this.phone
        }
      })
        .then((res) => {
          if (res.code === 200) {
            this.startCountDown();
            this.disabled = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.disabled = false;
        });
    },
    init() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.lefTime = 0;
    },
    startCountDown() {
      this.lefTime = this.countDown;
      this.timer = setInterval(() => {
        if (this.lefTime > 0) {
          this.lefTime--;
        } else {
          this.disabled = false;
        }
        this.$emit("change", this.lefTime);
      }, 1000);
    }
  }
};
</script>
